import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'modules/redux';
import * as Layout from 'components/Layout';
import PageTitle from 'components/PageTitle';
import Retool from 'react-retool';
import { getRetoolEmbedUrl } from 'modules/api/utils';

const Template: React.FunctionComponent = () => {
  const [embedUrl, setEmbedUrl] = useState<string | undefined>(undefined);
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (user?.email && user?.nickname && user?.sub && user?.org_id) {
      getRetoolEmbedUrl(user.email, user.nickname, user.sub, user.org_id).then(
        url => setEmbedUrl(url),
      );
    }
  }, [user]);

  return (
    <React.Fragment>
      <PageTitle enableBreadcrumbs={false} title="Crashes" />
      <Layout.Container>
        {embedUrl ? <Retool url={embedUrl} /> : <div></div>}
      </Layout.Container>
    </React.Fragment>
  );
};

export default React.memo(Template);
