import { ROUTE, routes } from 'modules/router/constants';

type IsAppRoute = {
  isEngagementRoute?: boolean;
  isPoliciesRoute?: boolean;
  isSpeedValidationRoute?: boolean;
  isCrashRoute?: boolean;
  title: string | null;
};

export const isAppRoute = (path: string): IsAppRoute => {
  switch (path) {
    case ROUTE.POLICY_SEARCH:
    case ROUTE.REPORTS:
      return {
        isPoliciesRoute: true,
        title: 'Policies',
      };
    case ROUTE.ENGAGEMENT_SEARCH:
      return {
        isEngagementRoute: true,
        title: 'Engagement',
      };
    case ROUTE.EVENTS:
      return {
        isSpeedValidationRoute: true,
        title: 'Speed Validation',
      };
    case ROUTE.CRASHES:
      return {
        isCrashRoute: true,
        title: 'Crash',
      };
    default:
      return {
        title: routes.filter(route => route.path === path)[0]?.title || null,
      };
  }
};
