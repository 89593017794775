import { isIE } from 'react-device-detect';
import { logger } from 'redux-logger';
import { Middleware } from 'redux';
import { ThunkMiddleware } from 'redux-thunk';

import { isAdmin } from 'modules/admin';
import createAppMiddleware from 'modules/app/middleware';
import createAuthMiddleware from 'modules/auth/middleware';
import createConfigMiddleware from './states/config/middleware';
import createDrawersMiddleware from 'modules/drawers/middleware';
import createEngagementMiddleware from './states/engagement/middleware';
import createEventsMiddleware from './states/events/middleware';

import createModalsMiddleware from 'modules/modals/middleware';

import createNotificationsMiddleware from 'modules/notifications/middleware';
import createPolicyMiddleware from './states/policy/middleware';

const middleware = (): Array<ThunkMiddleware | Middleware> => {
  const middleware = [
    createAppMiddleware(),
    createAuthMiddleware(),
    createDrawersMiddleware(),
    createModalsMiddleware(),
    createNotificationsMiddleware(),
    createConfigMiddleware(),
    createPolicyMiddleware(),
    createEventsMiddleware(),
    createEngagementMiddleware(),
  ];
  isAdmin && !isIE && middleware.push(logger);
  return middleware;
};

export default middleware;
